import { clone } from '~/helpers/javascript';
import { getVideosBySections, updateProportionInVideos } from '../helpers/helper';
import {
  saveVideosHistory,
  getVideosHistory,
  updateVideosHistory
} from '../helpers/history.services';
import { db } from '../helpers/db';
import { sendMultiplePrompts } from '../helpers/gpt';

export const useHistoryStore = (set, get) => ({
  // state
  isLoadingHistorical: false,
  historicalSections: [],
  videosByHistoricalSections: [],
  historyPagination: { currentPage: 1, totalPages: 1 },
  isLoadingPromptsHistory: false,
  promptHistory: [],
  promptSuggestions: [],

  // actions
  saveVideosHistoryAction: async (section) => {
    try {
      Promise.allSettled(
        section.videos.map((video, videoIdx) => {
          const _video = {
            ...video,
            meta: {
              ...video.meta,
              medias: section.medias[videoIdx],
              voiceover: section.voiceover[videoIdx],
              voiceoverAudio: section.voiceoverAudio.audios[videoIdx],
              music: section.music[videoIdx]
            }
          };

          return saveVideosHistory(_video);
        })
      ).then((data) => {
        // console.log('POST___DATA', data);
      });
    } catch (error) {
      console.error(error);
    }
  },

  getVideosHistoryAction: async (page) => {
    try {
      set({ isLoadingHistorical: true });
      const _videos = await getVideosHistory(get().user._id, page);
      // console.log('___', _videos);
      const _historyPagination = {
        currentPage: page,
        totalPages: _videos.totalPages
      };

      let _sections = [];
      _videos.videos.forEach((video) => {
        if (video.category) {
          let section = {
            id: video._id,
            videos: video.videos,
            category: video.category,
            isLoading: false,
            errors: [],
            voiceover: video.voiceover,
            creation: { time: '00:00', step: 0 },
            voiceoverAudio: { disabled: false, audios: video.voiceoverAudio },
            medias: video.medias,
            music: video.music,
            prompt: video.prompt,
            publishedDate: video.publishedDate
          };

          _sections.push(section);
        } else {
          _sections.push(video.videos[0]);
        }
      });

      _sections = updateProportionInVideos(get().videoProportion, _sections);
      // console.log('_sections_', _sections);

      set(
        {
          historicalSections: _sections,
          videosByHistoricalSections: getVideosBySections(_sections),
          isLoadingHistorical: false,
          historyPagination: _historyPagination
        },
        false,
        'init'
      );
    } catch (error) {
      console.error(error);
    }
  },

  updateVideosHistoryAction: async (userId, section) => {
    try {
      Promise.allSettled(
        section.videos.map((video, videoIdx) => {
          const _video = {
            ...video,
            meta: {
              ...video.meta,
              medias: section.medias[videoIdx],
              voiceover: section.voiceover[videoIdx],
              voiceoverAudio: section.voiceoverAudio.audios[videoIdx],
              music: section.music[videoIdx]
            }
          };

          // console.log('_video', _video);
          return updateVideosHistory(userId, _video.videoId, _video);
        })
      ).then((data) => {
        // console.log('PUT___DATA', data);
      });
    } catch (error) {
      console.error(error);
    }
  },

  updateOneVideoHistoryAction: (userId, section, videoIdx) => {
    try {
      let _video = section.videos[videoIdx];
      _video = {
        ..._video,
        meta: {
          ..._video.meta,
          medias: section.medias[videoIdx],
          voiceover: section.voiceover[videoIdx],
          voiceoverAudio: section.voiceoverAudio.audios[videoIdx],
          music: section.music[videoIdx]
        }
      };

      // console.log('_video', _video);
      updateVideosHistory(userId, _video.videoId, _video);
    } catch (error) {
      console.log('ERROR! - ', error);
    }
  },

  downloadVideosFromHistoryAction: async (sectionIdx) => {
    if (get().downloadStatus === 'finished') {
      set({
        videosToDownload: [],
        downloadStatus: 'default',
        componentStates: 'default'
      });
    }

    const sections = get().historicalSections;

    const videos = clone(sections[sectionIdx].videos);

    const _videos = clone(get().videosToDownload);
    _videos = [..._videos, ...videos];
    set({ videosToDownload: _videos });

    await db.videosToDownload.put({
      id: 1,
      videos: _videos
    });

    const totalVideosBtn = document.querySelector('#btn__total-videos');
    if (totalVideosBtn) {
      totalVideosBtn.classList.add('shake');
      setTimeout(() => {
        totalVideosBtn.classList.remove('shake');
      }, 500);
    }

    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'add-video-from-history-to-download'
    });
  },

  getPromptsHistoryAction: async () => {
    try {
      set({ isLoadingPromptsHistory: true });
      const _history = await getVideosHistory(get().user._id, 1, 10);
      let _prompts = [];

      _history.videos?.forEach((v) => {
        _prompts.push({ prompt: v.prompt });
      });

      const messages = [
        {
          role: 'system',
          content: `
          Suggest new videos considering the prompt of my previous videos (that are related to previous creations and are not identical, use your creativity and enrich the content)

          Return an array with 8 suggestions with the following structure:
          [
          {
          "prompt": "Video prompt"
          }
          ]`
        },
        {
          role: 'user',
          content: JSON.stringify(_prompts)
        }
      ];

      const _data = await sendMultiplePrompts(messages);
      // console.log('___', JSON.parse(_data));

      set({
        promptHistory: _prompts,
        promptSuggestions: JSON.parse(_data),
        isLoadingPromptsHistory: false
      });
    } catch (error) {
      console.error(error);
    }
  }
});
